import React from 'react'
import PropTypes from 'prop-types'

import TracklineBackground from '@objects/tracklineBackground'
import useStaticModule from '@hooks/useStaticModule'
import Container from '@objects/container'
import Copy from '@objects/copy'

import { useIntl } from 'react-intl'
import Headline from '@objects/headline'

function Impressum({ data, pageContext }) {
  const intl = useIntl()
  const { getStaticFieldValue } = useStaticModule(pageContext.modules)

  const content = getStaticFieldValue('imprint.text', 'imprint.text.copy')

  return (
    <Container role="region" aria-label="Impressum">
      <TracklineBackground track={2} bottom={'30%'} />
      <TracklineBackground track={3} right mirror top={'0%'} />
      <Headline
        level={1}
        className="mb-6  text-3xl md:text-5xl lg:w-10/12 lg:mx-auto"
        ariaLabel={intl.formatMessage({ id: 'impressum.headline' })}
      >
        {intl.formatMessage({ id: 'impressum.headline' })}
      </Headline>
      <Copy className="lg:w-10/12 lg:mx-auto" html={content} div />
    </Container>
  )
}

Impressum.propTypes = {
  data: PropTypes.any,
  pageContext: PropTypes.object,
}

export default Impressum
